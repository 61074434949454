import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { DefaultInputStyles } from '../../util/const'

const SelectInput = ({
  label,
  value,
  options,
  onChange,
  onInputChange,
  error,
  filterOption,
  getOptionLabel,
  getOptionValue,
  loadingMessage,
  noOptionsMessage,
  defaultInputValue,
  defaultValue,
  load,
  formatOptionLabel,
  components,
  placeholder,
  isClearable,
  isSearchable,
  styles,
  isMulti,
  disabled,
  menuPosition,
}) => {
  const [isMounted, setIsMounted] = useState(false)

  const defaultNoOptionsMessage = () => 'Valikuid ei leitud'

	// Must be deleted once
	// https://github.com/JedWatson/react-select/issues/5459 is fixed.
	useEffect(() => setIsMounted(true), [])

  return !isMounted ? <></> : (
    <div className={`input-wrapper input-wrapper--select${error ? ' input-wrapper--error' : ''}`}>
      <label>
        {label && <span>{label}</span>}
        {
          load ?
            <AsyncSelect
              loadOptions={load}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              loadingMessage={loadingMessage}
              noOptionsMessage={noOptionsMessage || defaultNoOptionsMessage}
              value={value}
              onChange={onChange}
              defaultInputValue={defaultInputValue}
              defaultValue={defaultValue}
              formatOptionLabel={formatOptionLabel}
              components={components}
              placeholder={placeholder || 'Vali'}
              isMulti={isMulti}
              isDisabled={disabled}
              styles={{
                ...DefaultInputStyles,
                ...styles,
              }}
            />
            :
            <Select
              options={options}
              onChange={onChange}
              value={value}
              isClearable={isClearable}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              onInputChange={onInputChange}
              loadingMessage={loadingMessage}
              noOptionsMessage={noOptionsMessage || defaultNoOptionsMessage}
              filterOption={filterOption}
              formatOptionLabel={formatOptionLabel}
              components={components}
              placeholder={placeholder || 'Vali'}
              isSearchable={isSearchable}
              isMulti={isMulti}
              menuPosition={menuPosition}
              isDisabled={disabled}
              styles={{
                ...DefaultInputStyles,
                ...styles,
              }}
            />
        }
      </label>
      {error &&
        <div className="input-error">{error}</div>
      }
    </div>
  )
}
export default SelectInput