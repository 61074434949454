import React, { useEffect, useState } from 'react'
import TextInput from '../components/Input/TextInput'
import Textarea from '../components/Input/Textarea'
import SelectInput from '../components/Input/SelectInput'
import FileUpload from '../components/Input/FileUpload'
import Checkbox from '../components/Input/Checkbox'
import Button from '../components/Input/Button'
import BoxNotification from '../components/BoxNotification'
import { padWithZero } from '../util/helpers'

const InitialFields = {
    name: '',
    birthday: '',
    company: '',
    sector: '',
    position: '',
    timeInPosition: '',
    education: '',
    career: '',
    recognition: '',
    hobbies: '',
    fillName: '',
    email: '',
    phone: '',
    photo: '',
    photoAuthor: '',
    consent: false,
    responderName: '',
    responderEmail: '',
    responderPhone: '',
    question1: '',
    question2: '',
    question3: '',
}

const PositionTimes = [
    {
        value: 'ametis_kuni_5_aastat',
        label: 'Ametis kuni 5 aastat',
    },
    {
        value: 'ametis_6_10_aastat',
        label: 'Ametis 6-10 aastat',
    },
    {
        value: 'ametis_11_15_aastat',
        label: 'Ametis 11-15 aastat',
    },
    {
        value: 'ametis_16_20_aastat',
        label: 'Ametis 16-20 aastat',
    },
    {
        value: 'ametis_21_25_aastat',
        label: 'Ametis 21-25 aastat',
    },
    {
        value: 'ametis_yle_25_aasta',
        label: 'Ametis üle 25 aasta',
    },
]

const InitialErrors = {
    name: null,
    email: null,
    phone: null,
    birthday: null,
    company: null,
    sector: null,
    position: null,
    timeInPosition: null,
    consent: null,
    responderName: null,
}

const Form = ({
    token,
}) => {

    const [fields, setFields] = useState(InitialFields)
    const [errors, setErrors] = useState(InitialErrors)
    const [generalError, setGeneralError] = useState(null)
    const [successMessage, setSuccessMessage] = useState(null)
    const [file, setFile] = useState(null)
    const [photo, setPhoto] = useState(null)
    const [showResponseFields, setShowResponseFields] = useState(false)
    const [loading, setLoading] = useState(false)

    const fetchPerson = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API}/person`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })

            const json = await result.json()

            const birthday = new Date(json.birthday)

            setFields({
                ...fields,
                ...json,
                birthday: json?.birthday ? `${birthday.getFullYear()}-${padWithZero(birthday.getMonth() + 1, 2)}-${padWithZero(birthday.getDate(), 2)}` : '',
                timeInPosition: json.timeInPosition ? PositionTimes.find(time => time.value === json.timeInPosition) : null,
            })

            if (json?.responderName) setShowResponseFields(true)
            if (!json?.photo) return

            const res = await fetch(`${process.env.REACT_APP_API}/file/${json.photo}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })

            const blob = await res.blob()

            if (!blob) return

            setPhoto(URL.createObjectURL(blob))
        } catch (err) {
            console.log('fetch person error', err)
        }
    }

    useEffect(() => {
        fetchPerson()
    }, [])

    const setField = (field, value) => {
        setErrors({
            ...errors,
            [field]: null,
        })
        setGeneralError(false)
        setFields({
            ...fields,
            [field]: value,
        })
    }

    const validate = () => {
        setSuccessMessage(null)
        setGeneralError(null)

        const err = { ...InitialErrors }
        let hasError = false

        if (!fields.name) {
            err.name = 'Nimi on nõutud väli'
            hasError = true
        }

        if (!fields.email) {
            err.email = 'E-post on nõutud väli'
            hasError = true
        }

        if (/\S+@\S+\.\S+/.test(fields.email) === false) {
            err.email = 'Palun sisesta korrektne e-posti aadress'
            hasError = true
        }

        if (!fields.phone) {
            err.phone = 'Telefon on nõutud väli'
            hasError = true
        }

        if (!fields.birthday) {
            err.birthday = 'Sünnipäev on nõutud väli'
            hasError = true
        }

        if (!fields.company) {
            err.company = 'Ettevõte on nõutud väli'
            hasError = true
        }

        if (!fields.sector) {
            err.sector = 'Tegevusala on nõutud väli'
            hasError = true
        }

        if (!fields.position) {
            err.position = 'Ametikoht on nõutud väli'
            hasError = true
        }

        if (!fields.consent) {
            err.consent = 'Palun nõustu andmete kasutamisega'
            hasError = true
        }

        if (showResponseFields) {
            if (!fields.responderName) {
                err.responderName = 'Väli on nõutud'
                hasError = true
            }
        }

        setErrors(err)

        if (hasError) {
            setGeneralError('Palun täida kõik kohustuslikud väljad')
        }

        return !hasError
    }

    const uploadPhoto = async () => {
        if (!file) return

        try {
            const formData = new FormData()
            formData.append('photo', file)

            const res = await fetch(`${process.env.REACT_APP_API}/person/photo`, {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })

            const json = await res.json()

            if (!json?.fileName) {
                setGeneralError('Viga pildi üleslaadimisel')
                return
            }

            return json.fileName
        } catch (err) {
            setGeneralError('Viga pildi üleslaadimisel')
            return
        }
    }

    const handleSave = async () => {
        if (!validate()) return

        setLoading(true)

        try {
            const data = {
                ...fields,
                timeInPosition: fields.timeInPosition?.value || null,
                birthday: fields.birthday ? new Date(fields.birthday) : null,
            }

            if (file) {
                const fileName = await uploadPhoto()

                if (!fileName) return

                data.photo = fileName
            }

            const res = await fetch(`${process.env.REACT_APP_API}/person/save`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            })

            if (res.status === 400) {
                setGeneralError('Kontrolli, et kõik väljad oleksid täidetud')
                return
            }

            if (res.status === 200) {
                setSuccessMessage('Andmed edukalt salvestatud')
            }
        } catch (err) {
            console.log('save error', err)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className='form'>
            <div className='form-group intro'>
                <p>Sel aastal ilmuv tippjuhtide leksikon on järjekorras üheteistkümnes. Äripäeva ja Infopanga koostöös valmiv “Eesti tippjuhid 2024” eesmärgiks on väärtustada Eesti suuremate ettevõtete ja riigiasutuste juhte, kelle otsustest ja igapäevatööst sõltub majanduse käekäik. Lisaks juhtide tööalasele infole on lugejatel võimalik saada ka ülevaade, kuidas meie majanduse võtmeisikud näevad tulevikku.</p>
                <p>Leksikonis osalemiseks palume Teil täita allolev ankeet.</p>
            </div>
            <div className='form-group contact'>
                <div className='photo'>
                    <FileUpload
                        onChange={setFile}
                        label={'Foto lisamiseks vajuta siia'}
                        supportedTypesText={'Palun laadi üles kvaliteetne, trükimaterjalis kasutamiseks sobiv foto. Lubatud formaadid jpg, tiff, png.'}
                        allowedTypes={'image/png,image/jpeg,image/jpg,image/tiff'}
                        value={photo}
                    />
                    <TextInput
                        label='Foto autor'
                        onChange={e => setField('photoAuthor', e.target.value)}
                        value={fields.photoAuthor || ''}
                    />
                </div>
                <div className='contact-info'>
                    <TextInput
                        label='Nimi*'
                        onChange={e => setField('name', e.target.value)}
                        value={fields.name || ''}
                        autoComplete='name'
                        error={errors.name}
                    />
                    <TextInput
                        label='E-posti aadress*'
                        onChange={e => setField('email', e.target.value)}
                        value={fields.email || ''}
                        type={'email'}
                        autoComplete='email'
                        error={errors.email}
                    />
                    <TextInput
                        label='Telefon*'
                        onChange={e => setField('phone', e.target.value)}
                        value={fields.phone || ''}
                        type={'tel'}
                        autoComplete='tel'
                        error={errors.phone}
                    />
                    <TextInput
                        label='Sünnipäev*'
                        type={'date'}
                        onChange={e => setField('birthday', e.target.value)}
                        value={fields.birthday || ''}
                        placeholder={'pp.kk.aaaa'}
                        error={errors.birthday}
                    />
                </div>
            </div>
            <div className='form-group company'>
                <div className='form-row'>
                    <TextInput
                        label='Ettevõte*'
                        onChange={e => setField('company', e.target.value)}
                        value={fields.company || ''}
                        error={errors.company}
                    />
                    <TextInput
                        label='Tegevusala*'
                        onChange={e => setField('sector', e.target.value)}
                        value={fields.sector || ''}
                        error={errors.sector}
                    />
                </div>
                <div className='form-row'>
                    <TextInput
                        label='Ametinimetus*'
                        onChange={e => setField('position', e.target.value)}
                        value={fields.position || ''}
                        error={errors.position}
                    />
                    <SelectInput
                        label={'Aeg ametis'}
                        options={PositionTimes}
                        placeholder={'Tee valik'}
                        onChange={(option) => setField('timeInPosition', option)}
                        value={fields.timeInPosition || null}
                        error={errors.timeInPosition}
                    />
                </div>
            </div>
            <div className='form-group'>
                <Textarea
                    label='Hariduskäik (järjestatuna madalamast kõrgema suunas, aastad, õppeasutus, eriala)'
                    onChange={e => setField('education', e.target.value)}
                    value={fields.education || ''}
                />
                <Textarea
                    label='Töö ja ametikäik (praegune põhitöökoht, varasemad ametikohad olevikust mineviku suunas: aasta, organisatsiooni nimi ja ametinimetus)'
                    onChange={e => setField('career', e.target.value)}
                    value={fields.career || ''}
                />
                <Textarea
                    label='Avalikud tunnustused (koos aastaarvuga, palun nimetage kuni 5 olulisemat tunnustust)'
                    onChange={e => setField('recognition', e.target.value)}
                    value={fields.recognition || ''}
                />
                <Textarea
                    label='Hobid'
                    onChange={e => setField('hobbies', e.target.value)}
                    value={fields.hobbies || ''}
                />
            </div>
            <div className='form-group'>
                <Textarea
                    label='Millised on Eesti ärikeskkonna tugevused ja nõrkused? Milline võiks Eesti ärikeskkond olla 10 aasta pärast?'
                    onChange={e => setField('question1', e.target.value)}
                    value={fields.question1 || ''}
                />
                <Textarea
                    label='Soovitage oma kogemusest parimaid praktikaid, kuidas hoida ja motiveerida töötajaid?'
                    onChange={e => setField('question2', e.target.value)}
                    value={fields.question2 || ''}
                />
                <Textarea
                    label='Milliseks hindate lähiaastatel AI (tehisaru) mõju nii Teie ettevõttele kui ka Eesti ärikeskkonnale üldiselt?'
                    onChange={e => setField('question3', e.target.value)}
                    value={fields.question3 || ''}
                />
            </div>
            <div className='form-group responder'>
                <Checkbox
                    label={`Kas küsimustikku täidab keegi teine?`}
                    value={showResponseFields}
                    onChange={e => setShowResponseFields(e.target.checked)}
                />
                {
                    showResponseFields ?
                        <>
                            <TextInput
                                label='Vastaja nimi*'
                                onChange={e => setField('responderName', e.target.value)}
                                value={fields.responderName || ''}
                                error={errors.responderName}
                            />
                            <TextInput
                                label='Vastaja email'
                                onChange={e => setField('responderEmail', e.target.value)}
                                value={fields.responderEmail || ''}
                            />
                            <TextInput
                                label='Vastaja telefon'
                                onChange={e => setField('responderPhone', e.target.value)}
                                value={fields.responderPhone || ''}
                            />
                        </>
                        :
                        <></>
                }
            </div>
            <div className='form-group actions'>
                <Checkbox
                    label={`Nõustun ülalolevate andmete avaldamisega Äripäeva trükistes ja veebikeskkondades`}
                    value={fields.consent || false}
                    onChange={e => setField('consent', e.target.checked)}
                    error={errors.consent}
                />
                <Button
                    label={'Salvesta'}
                    onClick={handleSave}
                    loading={loading}
                    disabled={loading}
                />
            </div>
            {
                generalError ?
                    <BoxNotification
                        type={'error'}
                        message={generalError}
                    />
                    :
                    <></>
            }
            {
                successMessage ?
                    <BoxNotification
                        type={'success'}
                        message={successMessage}
                    />
                    :
                    <></>
            }
        </div>
    )
}

export default Form