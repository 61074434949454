const padWithZero = (num, places) => String(num).padStart(places, '0')

const getToken = () => {
    const params = new URLSearchParams(window.location.search)
    return params.get('t')
}

export {
    padWithZero,
    getToken,
}