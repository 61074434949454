import React from 'react'
import { Spinner } from 'react-activity'

const Button = ({
  onClick,
  label,
  disabled,
  className,
  loading,
  icon,
  backgroundColor,
  loadingColor,
  small,
  bordered,
  large,
}) => (
  <button
    className={`btn${className ? ` ${className}` : ''}${loading ? ` btn--loading` : ''}${icon ? ` btn--has-icon` : ''}${small ? ` btn-small` : ''}${large ? ` btn-large` : ''}${bordered ? ` btn-bordered` : ''}`}
    onClick={onClick}
    disabled={disabled || loading}
    style={{
      backgroundColor,
    }}
  >
    {
      icon ?
        <div className={`btn--icon${!label ? ' btn--icon-no-label' : ''}`}>{icon}</div>
        :
        <></>
    }
    {
      label ?
        <span>{label}</span>
        :
        <></>
    }
    {
      loading ?
        <Spinner size={9} color={loadingColor} speed={1} />
        :
        <></>
    }
  </button>
)

export default Button