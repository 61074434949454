import 'react-activity/dist/library.css'
import Form from './screens/Form'
import { getToken } from './util/helpers'

function App() {
  const token = getToken()

  if (!token) return <></>
  
  return (
    <div className='app'>
      <header className='app-header'>
        <h1>Leksikon “Eesti tippjuhid 2024” ankeet</h1>
        <div className='branding'>
          <div className='branding-logo aripaev'></div>
          <div className='branding-logo infopank'></div>
        </div>
      </header>
      <div className='app-content'>
        <Form token={token} />
      </div>
      <footer className='app-footer'></footer>
    </div>
  )
}

export default App
