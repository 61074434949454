const DefaultInputStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        outline: 'none !important',
        borderColor: state?.isFocused ? '#000' : 'rgb(245, 245, 245)',
        borderWidth: '2px',
        borderRadius: '8px',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '10px',
        fontSize: '16px',
        boxShadow: state?.isFocused ? '0 0 0 1px #000' : 'none',
        '&:hover': {
            borderColor: state?.isFocused ? '#000' : 'rgb(245, 245, 245)',
            borderWidth: '2px',
            borderRadius: '8px',
            boxShadow: state?.isFocused ? '0 0 0 1px #000' : 'none',
        },
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor: state?.isSelected ? '#000' : '#fff',
        '&:hover': {
            backgroundColor: state?.isSelected ? '#000' : 'rgb(245, 245, 245)',
        },
    }),
    menu: (baseStyles) => ({
        ...baseStyles,
        borderRadius: '8px',
    }),
    menuList: (baseStyles) => ({
        ...baseStyles,
        borderRadius: '8px',
    }),
}

export {
    DefaultInputStyles,
}