import React from 'react'

const BoxNotification = ({
    message,
    type,
}) => {
    if (!message) return <></>

    return (
        <div
            className={`box-notification box-notification--${type}`}
        >
            { message }
        </div>
    )
}

export default BoxNotification