import React, { useEffect, useMemo, useState } from 'react'

const FileUpload = ({
    label,
    onChange,
    allowedTypes,
    supportedTypesText,
    value,
}) => {
    const [isOver, setIsOver] = useState(false)
    const [file, setFile] = useState()
    const fileUrl = useMemo(() => file?.name ? URL.createObjectURL(file) : '', [file])

    useEffect(() => {
        if (!onChange) return

        onChange(file)
    }, [file])

    const handleChange = ({ target: { files } }) => {
        if (!files?.length) return

        setFile(files[0])
    }

    const handleDrop = (e) => {
        e.preventDefault()

        if (e.dataTransfer.items?.length && e.dataTransfer.items[0].kind === 'file') {
            setFile(e.dataTransfer.items[0].getAsFile())
            return
        }

        if (e.dataTransfer.files?.length) {
            setFile(e.dataTransfer.files[0])
        }
    }

    const handleDragOver = (e) => {
        e.preventDefault()
        setIsOver(true)
    }

    const handleDragEnd = () => {
        setIsOver(false)
    }

    return (
        <div className={`file-upload${isOver || file ? ' file-upload--over' : ''}`} onDrop={handleDrop} onDragOver={handleDragOver} onDragLeave={handleDragEnd}>
            {
                !file?.name && !value ?
                    <label htmlFor="document-upload">
                        <span className='document-upload-icon'>
                            <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g transform="translate(-912.000000, -48.000000)" fillRule="nonzero">
                                        <g transform="translate(912.000000, 48.000000)">
                                            <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z" fillRule="nonzero"></path>
                                            <path d="M20,3 C21.1046,3 22,3.89543 22,5 L22,19 C22,20.1046 21.1046,21 20,21 L4,21 C2.89543,21 2,20.1046 2,19 L2,5 C2,3.89543 2.89543,3 4,3 L20,3 Z M20,5 L4,5 L4,15.1005 L8.9948,10.1057 C9.48296,9.61757 10.2744,9.61757 10.7626,10.1057 L14.8284,14.1716 L16.0659,12.9342 C16.554,12.446 17.3455,12.446 17.8336,12.9342 L20,15.1005 L20,5 Z M15.5,7 C16.3284,7 17,7.67157 17,8.5 C17,9.32843 16.3284,10 15.5,10 C14.6716,10 14,9.32843 14,8.5 C14,7.67157 14.6716,7 15.5,7 Z" fill="rgb(245, 245, 245)"></path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                        <p>{ label || 'Pildi lisamiseks vajuta siia' }</p>
                        {
                            supportedTypesText ?
                                <div className='file-upload--supported-file-types'>{supportedTypesText}</div>
                                :
                                <></>
                        }
                    </label>
                    :
                    <label htmlFor="document-upload">
                        <div className='document-preview' style={{ backgroundImage: `url(${fileUrl || value})` }} />
                    </label>
            }
            <input id="document-upload" type='file' onChange={handleChange} accept={allowedTypes} />
        </div>
    )
}

export default FileUpload